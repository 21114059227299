<template>
	<div class="grid" style="padding-top: 60px;">
		<div class="col-4"></div>
		<div class="col-4">
			<div class="card login-panel p-fluid">	

			<div class="login-panel-content">
				<div class="grid">
					<div class="col-3" style="text-align:left">
						<img src="layout/images/login/icon-login.svg" alt="avalon-vue"/>
					</div>
					<div class="col-9" style="text-align:left">
						<h2 class="welcome-text">Bienvenido</h2>
						<span class="guest-sign-in">Inicia sesion para ingresar</span>
					</div>
					<div class="col-12" style="text-align:left">
						<label class="login-label">Uuario</label>
						<div class="login-input">
							<InputText placeholder="Usuario" v-model="model.sUserName"/>
						</div>
					</div>
					<div class="col-12" style="text-align:left">
						<label class="login-label">Contraseña</label>
						<div class="login-input">
							<InputText type="password" placeholder="Contraseña" v-model="model.sPassword"/>
						</div>
					</div>
					<div class="col-12" style="text-align:left">
							<Button label="Iniciar Sesión" @click="login()"/>
					</div>
				</div>
			</div>
			</div>
		</div>
		<div class="col-4"></div>
	</div>
</template>

<script>
/* eslint-disable */
	export default {
		data() {
			return {
				model: {
					sUserName: '',
					sPassword: ''
				}
			}
		},
		methods: {

			async login() {
				//this.openLoading('Espere por favor...')
				axios.post(apiURL + 'Login', this.model)
				.then( (response) => {
					if(response.data.sErrors.length == 0){
						localStorage.setItem("dataPerson", JSON.stringify(response.data.userData))
                    	localStorage.userToken = response.data.sToken
						router.push('/home')
					}
					else
						Swal.fire(response.data.sErrors[0])
				} )
				.catch( (error) => {
					response.data.sErrors[error]
				} )
			}
		}		
	}
</script>

<style scoped>

</style>
