import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
	{
		path: '/',
		name: 'login',
		component: () => import('./pages/Login.vue')
	},
	{
		path: '/home',
		name: 'home',
		component: () => import('./pages/Home.vue')
	},
	{
		path: '/suppliers',
		name: 'suppliers',
		component: () => import('./pages/Suppliers.vue')
	},
	{
		path: '/customer',
		name: 'customers',
		component: () => import('./pages/Customers.vue')
	},
	{
		path: '/branches',
		name: 'branches',
		component: () => import('./pages/Branches.vue')
	},
	{
		path: '/brands',
		name: 'brands',
		component: () => import('./pages/Brands.vue')
	},
	{
		path: '/categories',
		name: 'categories',
		component: () => import('./pages/Categories.vue')
	},
	{
		path: '/purchases',
		name: 'purchases',
		component: () => import('./pages/Purchases.vue')
	},

	{
		path: '/products',
		name: 'products',
		component: () => import('./pages/ProductMaster.vue')
	},

	{
		path: '/transfer',
		name: 'transfer',
		component: () => import('./pages/Transfers.vue')
	},

	{
		path: '/inventory',
		name: 'inventory',
		component: () => import('./pages/Inventory.vue')
	},
	{
		path: '/reportes',
		name: 'reportes',
		component: () => import('./pages/Reportes.vue')
	},

	{
		path: '/receives',
		name: 'receives',
		component: () => import('./pages/Seller/ReceiveTransfers.vue')
	},

	{
		path: '/pointOfSale/:idTicket',
		name: 'pointOfSale',
		component: () => import('./pages/Seller/PointOfSale.vue'),
		props: true
	},
	{
		path: '/ticketPanel',
		name: 'ticketPanel',
		component: () => import('./pages/Seller/TicketPanel.vue')
	},
	{
		path: '/sales',
		name: 'sales',
		component: () => import('./pages/Sales.vue')
	},
	{
		path: '/promoCampaign',
		name: 'promoCampaign',
		component: () => import('./pages/PromoCampaign.vue')
	},
	{
		path: '/viewPromoCampaign/:idPromoCampaign',
		name: 'viewPromoCampaign',
		component: () => import('./components/PromoCampaigns/ViewPromoCampaign.vue'),
		props: true
	},
	{
		path: '/saleHistory',
		name: 'saleHistory',
		component: () => import('./pages/Seller/SaleHistory.vue')
	},
	{
		path: '/ImeiHistory',
		name: 'ImeiHistory',
		component: () => import('./components/Inventory/ImeiHistory.vue')
	},
	{
		path: '/billingRequestList',
		name: 'billingRequestList',
		component: () => import('./pages/BillingRequestList.vue')
	},
	{
		path: '/closeCashList',
		name: 'closeCashList',
		component: () => import('./components/Seller/Pos/CloseCashList.vue'),
		props: true
	},
	{
		path: '/sellerCloseCash',
		name: 'sellerCloseCash',
		component: () => import('./components/Seller/Pos/SellerCloseCash.vue'),
		props: true
	},
	{
		path: '/PriceList',
		name: 'PriceList',
		component: () => import('./pages/Seller/PriceList.vue'),
	},

	{
		path: '/promoViewBranch/:idPromoCampaign',
		name: 'promoViewBranch',
		component: () => import('./pages/PromoViewBranch.vue'),
		props: true
	},
	{
		path: '/stockAdjustment',
		name: 'stockAdjustment',
		component: () => import('./pages/StockAdjustment.vue')
	},
	{
		path: '/StockOut',
		name: 'stockOut',
		component: () => import('./pages/StockOut.vue')
	},
	{
		path: '/Returns',
		name: 'returns',
		component: () => import('./pages/Seller/Returns.vue')
	},
	//{
	//	path: '/',
	//	component: () => import('./components/DashboardDemo.vue')
	//},
	{
		path: '/dashboards/generic',
		name: 'dashboards',
		component: () => import('./components/DashboardDemo.vue')
	},
	{
		path: '/dashboards/dashboard_banking',
		name: 'dashboard_banking',
		component: () => import('./components/DashboardBanking.vue')
	},
	{
		path: '/formlayout',
		name: 'formlayout',
		component: () => import('./components/FormLayoutDemo.vue')
	},
	{
		path: '/input',
		name: 'input',
		component: () => import('./components/InputDemo.vue')
	},
	{
		path: '/floatlabel',
		name: 'floatlabel',
		component: () => import('./components/FloatLabelDemo.vue')
	},
	{
		path: '/invalidstate',
		name: 'invalidstate',
		component: () => import('./components/InvalidStateDemo.vue')
	},
	{
		path: '/button',
		name: 'button',
		component: () => import('./components/ButtonDemo.vue')
	},
	{
		path: '/table',
		name: 'table',
		component: () => import('./components/TableDemo.vue')
	},
	{
		path: '/list',
		name: 'list',
		component: () => import('./components/ListDemo.vue')
	},
	{
		path: '/tree',
		name: 'tree',
		component: () => import('./components/TreeDemo.vue')
	},
	{
		path: '/panel',
		name: 'panel',
		component: () => import('./components/PanelsDemo.vue')
	},
	{
		path: '/overlay',
		name: 'overlay',
		component: () => import('./components/OverlaysDemo.vue')
	},
	{
		path: '/media',
		name: 'media',
		component: () => import('./components/MediaDemo.vue')
	},
	{
		path: '/menus',
		component: () => import('./components/MenusDemo.vue'),
		children: [{
			path: '',
			component: () => import('./components/menu/PersonalDemo.vue')
		},
		{
			path: '/menus/seat',
			component: () => import('./components/menu/SeatDemo.vue')
		},
		{
			path: '/menus/payment',
			component: () => import('./components/menu/PaymentDemo.vue')
		},
		{
			path: '/menus/confirmation',
			component: () => import('./components/menu/ConfirmationDemo.vue')
		}]
	},
	{
		path: '/messages',
		name: 'messages',
		component: () => import('./components/MessagesDemo.vue')
	},
	{
		path: '/file',
		name: 'file',
		component: () => import('./components/FileDemo.vue')
	},
	{
		path: '/chart',
		name: 'chart',
		component: () => import('./components/ChartsDemo.vue')
	},
	{
		path: '/misc',
		name: 'misc',
		component: () => import('./components/MiscDemo.vue')
	},
	{
		path: '/icons',
		name: 'icons',
		component: () => import('./utilities/Icons.vue')
	},
	{
		path: '/empty',
		name: 'empty',
		component: () => import('./components/EmptyPage.vue')
	},
	{
		path: '/crud',
		name: 'crud',
		component: () => import('./pages/ProductMaster.vue')
	},
	{
		path: '/calendar',
		name: 'calendar',
		component: () => import('./pages/CalendarDemo.vue')
	},
	{
		path: '/timeline',
		name: 'timeline',
		component: () => import('./pages/TimelineDemo.vue')
	},
	{
		path: '/invoice',
		name: 'invoice',
		component: () => import('./pages/Invoice.vue')
	},
	{
		path: '/help',
		name: 'help',
		component: () => import('./pages/Help.vue')
	},
	{
		path: '/documentation',
		name: 'documentation',
		component: () => import('./components/Documentation.vue')
	},
	//{
	//    path: '/login',
	//    name: 'login',
	//    component: () => import('./pages/Login.vue')
	//},
	{
		path: '/error',
		name: 'error',
		component: () => import('./pages/Error.vue')
	},
	{
		path: '/notfound',
		name: 'notfound',
		component: () => import('./pages/NotFound.vue')
	},
	{
		path: '/access',
		name: 'access',
		component: () => import('./pages/Access.vue')
	},
	{
		path: '/wizard',
		name: 'wizard',
		component: () => import('./pages/Wizard.vue'),
	},
	{
		path: '/blocks',
		name: 'blocks',
		component: () => import('./components/BlocksDemo.vue')
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior() {
		return { left: 0, top: 0 };
	}
});

export default router;