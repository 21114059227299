<template>
    <div>
        <div class="field">
            <label for="name">Sucursal</label>
            <Dropdown id="idBranch" v-model="idBranch" :options="branches" optionValue="idOption" optionLabel="sOption" placeholder="Elige una Sucursal" ></Dropdown>
        </div>
        <div class="p-dialog-footer">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="Cancel"/>
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveBranch" />
        </div>          
    </div>
</template>

<script>
/* eslint-disable */


export default {
    
	data() {
		return {
            branches: null,
            idBranch: null,

		}
	},
	created() {
	},
	mounted() {
        console.log('Set Branch')
        this.getBranches()
	},
	methods: {
		async getBranches() {
            await axios.get(apiURL + 'Common/getBranches', { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
            .then( (response) => {
                this.branches = response.data.options
            } )
            .catch( (error)  => {
                console.log("error")
            } )  
		},
        saveBranch(){
            localStorage.idsu = this.idBranch
            this.showInfo('Información', 'La sucursal se configuró correctamente.')
            this.$emit('eventname', true)
        },
        Cancel(){
			this.$emit('eventname', false)
		}, 
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>