/* eslint-disable */
const globalMixin = {
    data() {
        return {

        }
    },
    methods: {
        openLoading(title) {
            Swal.fire({
                title,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        },
        isTokenExpired() {
            const expiry = (JSON.parse(Buffer.from(localStorage.userToken.split('.')[1], 'base64'))).exp;
            console.log(expiry)
            console.log('date: ' + (Math.floor((new Date).getTime() / 1000)))

            if ((Math.floor((new Date).getTime() / 1000)) >= expiry) {
                window.localStorage.clear()
                router.push('/')
            }

        },
        formatDate(value) {
            const date = new Date(value.toString());
            return date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear()
        },
        formatDateTime(value) {
            const date = new Date(value.toString());
            return date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ':' + date.getSeconds().toString().padStart(2, '0')
        },
        formatCurrency(value) {
            if (value == null)
                return ''
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        sumOfArrayWithParameter(array, parameter) {
            if (array.length == 0)
                return 0;

            let sum = null;
            if (array && array.length > 0 && typeof parameter === 'string') {
                sum = 0;
                for (let e of array) if (e && e.hasOwnProperty(parameter)) sum += e[parameter];
            }
            return sum;
        },

        //formatDate(value) {
        //    return value.toLocaleDateString('en-US', {
        //        day: '2-digit',
        //        month: '2-digit',
        //        year: 'numeric',
        //    });
        //},
        logout() {
            window.localStorage.clear()
            router.push('/')
        },
        showSuccess(sInfoMessage, sMessage) {
            this.$toast.add({ severity: 'success', summary: sInfoMessage, detail: sMessage, life: 3000 });
        },
        showInfo(sInfoMessage, sMessage) {
            this.$toast.add({ severity: 'info', summary: sInfoMessage, detail: sMessage, life: 3000 });
        },
        showWarn(sInfoMessage, sMessage) {
            this.$toast.add({ severity: 'warn', summary: sInfoMessage, detail: sMessage, life: 3000 });
        },
        showError(sInfoMessage, sMessage) {
            this.$toast.add({ severity: 'error', summary: sInfoMessage, detail: sMessage, life: 3000 });
        },
    }
}

export default globalMixin