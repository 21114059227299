<template>
	<div class="topbar clearfix">
		<Toast/>
		<div class="topbar-left">
			<router-link to="/">
				<button class="p-link">
					<img alt="Logo" src="layout/images/logo.png" class="topbar-logo"/>
				</button>
			</router-link>
		</div>

		<div class="topbar-center flex align-items-center justify-content-center">
			<p style="font-size: 20px; color:white"><b>{{ this.BranchData.sBranchName }}</b></p>
		</div>

		<div class="topbar-right">
			<button class="p-link" id="menu-button" @click="onMenuButtonClick">
				<i class="pi pi-angle-left"></i>
			</button>
			
			<button class="p-link" id="topbar-menu-button" @click="onTopbarMenuButtonClick">
				<i class="pi pi-bars"></i>
			</button>
			<ul :class="topbarItemsClass">
				<li v-if="profileMode === 'top' || horizontal"
					:class="['profile-item', {'active-top-menu': activeTopbarItem === 'profile'}]"
					@click="$emit('topbar-item-click',{originalEvent:$event,item:'profile'})">
					<button class="p-link">
						<img alt="User" class="profile-image" src="layout/images/avatar.png"/>
                        <div>
                            <span class="topbar-item-name">{{ this.sUsername }}</span>
                            
                        </div>
					</button>

					<transition name="layout-submenu-container">
						<ul class="layout-menu fadeInDown" v-show="activeTopbarItem === 'profile'">
							<li role="menuitem">
								<button class="p-link topbar-item-button">
									<i class="pi pi-fw pi-user"></i>
									<span>Profile</span>
								</button>
							</li>
							<li role="menuitem">
								<button class="p-link topbar-item-button">
									<i class="pi pi-fw pi-lock"></i>
									<span>Privacy</span>
								</button>
							</li>
							<li role="menuitem">
								<button class="p-link topbar-item-button">
									<i class="pi pi-fw pi-cog"></i>
									<span>Settings</span>
								</button>
							</li>
							<li role="menuitem">
								<button class="p-link topbar-item-button">
									<i class="pi pi-fw pi-sign-out"></i>
									<span>Logout</span>
								</button>
							</li>
						</ul>
					</transition>
				</li>



				<li :class="[{'active-top-menu': activeTopbarItem === 'notifications'}]"
					@click="$emit('topbar-item-click',{originalEvent:$event,item:'notifications'})">
					<button class="p-link">
						<i class="topbar-icon pi pi-fw pi-bell" v-badge.warning='iNotifications != 0 ? iNotifications : null'></i>
						<span class="topbar-item-name">Notifications</span>
					</button>

					<!--<transition name="layout-submenu-container">
						<ul class="layout-menu fadeInDown" v-show="activeTopbarItem === 'notifications'">
							<li role="menuitem">
								<a href="#">
									<i class="pi pi-fw pi-sliders-h"></i>
									<span>Pending tasks</span>
								</a>
							</li>
							<li role="menuitem">
								<a href="#">
									<i class="pi pi-fw pi-calendar"></i>
									<span>Meeting today at 3pm</span>
								</a>
							</li>
							<li role="menuitem">
								<a href="#">
									<i class="pi pi-fw pi-download"></i>
									<span>Download documents</span>
								</a>
							</li>
							<li role="menuitem">
								<a href="#">
									<i class="pi pi-fw pi-bookmark"></i>
									<span>Book flight</span>
								</a>
							</li>
						</ul>
					</transition>-->
				</li>


			</ul>
		</div>
        <Dialog v-model:visible="setBranchView" :style="{width: '30%'}" header="Configurar Sucursal" :modal="true" class="p-fluid">
            <SetBranch @eventname="closeSetBranch"/>
        </Dialog>		
	</div>
</template>

<script>
/* eslint-disable */
import SetBranch from "../src/components/Branches/SetBranch.vue"
	
	export default {
		expose: ['setNotification'],
		components:{
			SetBranch
		},
		data(){
			return {
				sUsername: '',
				setBranchView: false,
				BranchData: '',
				iNotifications: 0
			}
		},
		props: {
			topbarMenuActive: Boolean,
			profileMode: String,
			horizontal: Boolean,
			activeTopbarItem: String
		},
		methods: {
			setNotification(count){
				this.iNotifications = count
			},
			onMenuButtonClick(event) {
				this.$emit('menubutton-click', event);
			},
			onTopbarMenuButtonClick(event) {
				this.$emit('topbar-menubutton-click', event);
			},
			async getBranch(){
				if(localStorage.idsu == undefined){
					await Swal.fire({
						title: '¡Advertencia!',
						text: "No se ha Configurado la sucursal y es absolutamente necesario para operar correctamente el sistema",
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ir a Configuración!'
					}).then((result) => {
						if (result.isConfirmed) {
							
						}
						else{
							this.BranchData = {
								idBranch: 0,
								sBranchName: 'No Sucursal'
							}
						}
					})		
					this.setBranchView = true			
					return
				}

				await axios.get(apiURL + 'Branch/findBranch?idBranch=' + localStorage.idsu, { headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + localStorage.userToken
					} })
				.then( (response) => {
					if(response.data.sErrors.length > 0){
						this.BranchData = {
							idBranch: 0,
							sBranchName: 'No Sucursal'
						}
					}
					else{
						this.BranchData = response.data.branchData
					}
					
				} )
				.catch( (error)  => {
					this.BranchData = {
							idBranch: 0,
							sBranchName: 'No Sucursal'
						}
				} )  
			},
			closeSetBranch(isSet){
				if(isSet){
					this.getBranch()
				}
				this.setBranchView = false
			}
		},
		computed: {
			topbarItemsClass() {
				return ['topbar-items fadeInDown', {
					'topbar-items-visible': this.topbarMenuActive
				}];
			}
		},
	async mounted() {
		this.sUsername = JSON.parse(localStorage.getItem("dataPerson")).sName
        this.getBranch()
    },
	}
	
</script>
<style scoped>
		.topbar-center {
			padding: 15px;
			height: 60px;
			width: 300px;
			float: left;
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
		}
</style>